<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.scrutiny_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label-for="year"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type"
                    >
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        disabled
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Stall Category" vid="stall_category" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="stall_category"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.space_category') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="stall_category"
                        :options="stallCategoryList"
                        v-model="search.stall_category"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <b-button :disabled="isResult" type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.scrutiny_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" v-if="finalList.length > 0" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.scrutiny_report') }}
          </list-report-head>
          <b-row class="mb-2">
            <template v-if="finalList.length > 0">
            <b-col md="12" class="text-left">
              <strong>{{ parseInt(search.fair_id) ? getSearchFairName(search.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="12" class="text-left mt-3">
              <strong>{{ $t('fair_report.space_category') }} </strong>  : {{ getCategoryName(search.stall_category) }},
            <strong>{{ $t('ditfConfig.size') }}</strong> : {{ stallSize }},
             <strong> {{ $t('externalTradeFair.floor_price') }} </strong>  :{{ $n(getFloorPrice(search.stall_category), { minimumFractionDigits: 2 }) }} {{ $t('fair_report.vat_tax_excluding') }}
            </b-col>
            <b-col md="12" class="text-left mt-3" v-if="circular?.payment_received_last_date">
              <strong>{{ $t('fair_report.opening_date') }} </strong>  : {{ circular?.received_last_date }}
            </b-col>
            </template>
            <template v-else>
              <b-col md="12" class="text-center mt-3 text-danger" style="font-size: 25px!important;" v-if="circular?.payment_received_last_date">
              <strong>{{ $t('fair_report.opening_date') }} </strong>  : {{ circular?.received_last_date }}
            </b-col>
            </template>

          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <div style="text-align: center" class="mt-3"><strong>{{ $t('fair_report.allo_quoted_price_wise') }}</strong></div>
                  <template>
                    <table style="width:100%;" class="mb-4">
                      <thead>
                        <tr>
                          <th rowspan="2">{{ $t('globalTrans.sl_no') }}</th>
                          <th rowspan="2">{{ $t('exportCipReport.org_name_address') }}</th>
                          <th rowspan="2" style="width: 1%;">{{ $t('fair_report.preference_order') }}</th>
                          <th rowspan="2" style="width: 8%;">{{ $t('fair_report.stall_no') }}</th>
                          <th rowspan="2">{{ $t('fair_report.quoted_price') }} <br> {{ $t('fair_report.vat_tax_excluding') }}</th>
                          <th>{{ $t('fair_report.processing_fee') }}</th>
                          <th>{{ $t('fair_report.security_money') }}</th>
                          <th rowspan="2" style="width: 10%;">{{ $t('fair_report.allo_quoted_price_wise') }}</th>
                        </tr>
                        <tr>
                          <th>{{ $t('fair_report.date_trans_no') }}</th>
                          <th>{{ $t('fair_report.date_trans_no') }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="finalList.length > 0">
                        <template v-for="(app, index) in finalList">
                          <tr v-for="(item, index1) in app.details" :key="index1">
                            <slot v-if="index1 === 0">
                              <td :rowspan="app.details.length">{{ $n(index + 1) }}</td>
                              <td :rowspan="app.details.length">
                                <strong>{{ currentLocale == 'bn' ?  item.comp_name_bn : item.comp_name_en }}</strong>,
                                <br>
                                {{ AddressHelper.getCommonAddress(item) }}
                              </td>
                            </slot>
                            <td>{{ $n(index1+1) }}</td>
                            <td>{{ getStallName(item.stall_id) }}</td>
                            <td class="text-right">{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</td>
                            <slot v-if="index1 === 0">
                              <td :rowspan="app.details.length">
                                {{ $n(processingFee, { minimumFractionDigits: 2 }) }} <br>
                                {{ $t('globalTrans.transaction_no') }} : {{ app.details[0].transaction_no }}<br>
                                {{ $t('globalTrans.date') }} : {{ app.details[0].pay_date | dateFormat }}
                              </td>
                              <td :rowspan="app.details.length">
                                {{ $n(getSecurityMoney(app.details[0].quoted_price), { minimumFractionDigits: 2 }) }} <br>
                                {{ $t('globalTrans.transaction_no') }} : {{ app.details[0].transaction_no }} <br>
                                {{ $t('globalTrans.date') }} : {{ app.details[0].pay_date | dateFormat }}
                              </td>
                              <td :rowspan="app.details.length"></td>
                            </slot>
                          </tr>
                        </template>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="8" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfScrutinyReport, getMasterStall } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'

import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    // this.loadData()
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: null,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      priceType: 0,
      processingFee: 0,
      stallSize: '',
      circular: '',
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      stallList: [],
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        stall_category: 0,
        stall_id: 0
      },
      search_param: {
        year: 0,
        fair_id: 0,
        circular_type: 0,
        stall_category: 0,
        stall_id: 0
      },
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.stall_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalList = []
        this.stallList = []
        this.search.stall_id = 0
        this.stallList = this.getStall(newVal)
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    getStallName (stallCat) {
        const stall = this.stallList.find(item => item.value === parseInt(stallCat))
        return stall !== undefined ? stall.text : ''
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.isResult = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfScrutinyReport, this.search)
      if (result.success) {
        this.finalList = result.data
        this.circular = result.circular
        this.isResult = false
      } else {
        this.isResult = false
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getSecurityMoney (data) {
        const Obj = this.$store.state.TradeFairService.ditfCommonObj.securityMoneyList.find(item => item.year === this.search.year)
        const percentage = Obj !== undefined ? Obj.percentage : 0
        return (percentage * data) / 100
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getFloorPrice (catId) {
      const cat = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => item.value === catId)
      if (cat !== undefined) {
        this.priceType = cat.price_type
        this.processingFee = cat.processing_fee
        this.stallSize = this.$i18n.locale === 'bn' ? cat.size_bn : cat.size_en
        return cat.floor_price
      } else {
        return 0
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getCategoryName (id) {
      const companyType = this.stallCategoryList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    async getStall (stallCat) {
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, getMasterStall + '/' + stallCat)
      if (result.success) {
        this.stallList = result.data
      } else {
        this.stallList = []
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign(
        {
        request_type: 'pdf',
        local: this.$i18n.locale,
        org_id: 2,
        year: this.search.year,
        circular_type: this.search.circular_type,
        fair_id: this.search.fair_id,
        stall_category: this.search.stall_category,
        stallSize: this.stallSize,
        processingFee: this.processingFee,
        price_type: this.priceType,
        date_time: this.circular.payment_received_last_date,
        selection: false

        })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfScrutinyReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
